import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "card border-0",
  style: {"width":"100%"}
}
const _hoisted_2 = {
  class: "card-header border-0 mt-3",
  style: {"margin":"-12px 0px"}
}
const _hoisted_3 = {
  key: 0,
  class: "card-toolbar"
}
const _hoisted_4 = {
  class: "card-body pt-0",
  style: {"width":"100%"}
}
const _hoisted_5 = { class: "d-flex justify-content-left" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  class: "card-header border-0",
  style: {"margin":"-12px 0px"}
}
const _hoisted_9 = { class: "card-title" }
const _hoisted_10 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_11 = ["value"]
const _hoisted_12 = {
  key: 0,
  class: "card-toolbar"
}
const _hoisted_13 = {
  class: "container permissions-check",
  style: {"padding-left":"0px !important"}
}
const _hoisted_14 = { class: "row" }
const _hoisted_15 = {
  class: "text-primary h6",
  style: {"font-size":"1.5rem"}
}
const _hoisted_16 = { class: "form-check form-check-custom form-check-solid form-check-sm" }
const _hoisted_17 = { class: "list-group w-250px" }
const _hoisted_18 = {
  key: 0,
  style: {"display":"flex"},
  class: "list-group-item"
}
const _hoisted_19 = ["value", "checked"]
const _hoisted_20 = { class: "mx-3" }

export function render(_ctx: any,_cache: any) {
  const _component_AddNewRole = _resolveComponent("AddNewRole")!
  const _component_KTDatatable = _resolveComponent("KTDatatable")!
  const _component_Newpermission = _resolveComponent("Newpermission")!
  const _component_b_spinner = _resolveComponent("b-spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Card"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Card header"),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Card title"),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "card-title" }, [
          _createCommentVNode("begin::Search"),
          _createElementVNode("div", { class: "d-flex align-items-center position-relative my-1" }, [
            _createElementVNode("div", { class: "h4 text-primary" }, "Role Management")
          ]),
          _createCommentVNode("end::Search")
        ], -1 /* HOISTED */)),
        _createCommentVNode("begin::Card title"),
        _createCommentVNode("begin::Card toolbar"),
        (_ctx.assigned_permissions.includes('create-role'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_AddNewRole, {
                msg: { id: _ctx.UserId, bolean: _ctx.editRoleBolean }
              }, null, 8 /* PROPS */, ["msg"])
            ]))
          : _createCommentVNode("v-if", true),
        _createCommentVNode("end::Card toolbar")
      ]),
      _createCommentVNode("end::Card header"),
      _createCommentVNode("begin::Card body"),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_KTDatatable, {
          "table-data": _ctx.roles,
          total: _ctx.total,
          "table-header": _ctx.headerConfig,
          "enable-items-per-page-dropdown": true,
          loading: _ctx.loading,
          rowsPerPage: _ctx.pagination.page_size,
          onCurrentChange: _ctx.currentChange,
          onItemsPerPageChange: _ctx.itemsPerPageChange
        }, {
          "cell-id": _withCtx(({ row: roles }) => [
            _createElementVNode("div", null, _toDisplayString(roles.id), 1 /* TEXT */)
          ]),
          "cell-name": _withCtx(({ row: roles }) => [
            _createElementVNode("div", null, _toDisplayString(roles.name), 1 /* TEXT */)
          ]),
          "cell-action": _withCtx(({ row: roles }) => [
            _createElementVNode("div", _hoisted_5, [
              (_ctx.assigned_permissions.includes('update-role'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: "#",
                    class: "text-gray-600 text-hover-primary mx-1",
                    title: "Edit",
                    onClick: ($event: any) => (_ctx.getUserId(roles))
                  }, _cache[5] || (_cache[5] = [
                    _createElementVNode("div", {
                      class: _normalizeClass(`badge badge-light-success`)
                    }, [
                      _createElementVNode("i", {
                        class: "fas fa-edit text-success",
                        "data-bs-toggle": "modal",
                        "data-bs-target": "#addusermodal"
                      })
                    ], -1 /* HOISTED */)
                  ]), 8 /* PROPS */, _hoisted_6))
                : _createCommentVNode("v-if", true),
              (_ctx.assigned_permissions.includes('delete-role'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    href: "#",
                    class: "text-gray-600 text-hover-primary mx-1",
                    title: "Delete",
                    onClick: ($event: any) => (_ctx.delete_Exist_Role(roles.id))
                  }, _cache[6] || (_cache[6] = [
                    _createElementVNode("div", {
                      class: _normalizeClass(`badge badge-light-success`)
                    }, [
                      _createElementVNode("i", { class: "fas fa-trash text-danger" })
                    ], -1 /* HOISTED */)
                  ]), 8 /* PROPS */, _hoisted_7))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["table-data", "total", "table-header", "loading", "rowsPerPage", "onCurrentChange", "onItemsPerPageChange"])
      ]),
      _cache[10] || (_cache[10] = _createElementVNode("hr", { class: "mb-10" }, null, -1 /* HOISTED */)),
      _createCommentVNode("permission managment "),
      _createElementVNode("div", _hoisted_8, [
        _createCommentVNode("begin::Card title"),
        _createElementVNode("div", _hoisted_9, [
          _createCommentVNode("begin::Search"),
          _createElementVNode("div", _hoisted_10, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "h4 text-primary" }, "Permission Management", -1 /* HOISTED */)),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("select", {
                class: "form-select border ms-5 border-primary form-select-solid",
                "data-control": "select2",
                onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.permissionByRole(_ctx.assign_permission.role_id))),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.assign_permission.role_id) = $event)),
                "data-placeholder": "Select an option",
                "data-allow-clear": "true"
              }, [
                _cache[7] || (_cache[7] = _createElementVNode("option", {
                  disabled: "",
                  value: ""
                }, "Please Select Role", -1 /* HOISTED */)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (role, index) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: index,
                    value: role.id
                  }, _toDisplayString(role.name), 9 /* TEXT, PROPS */, _hoisted_11))
                }), 128 /* KEYED_FRAGMENT */))
              ], 544 /* NEED_HYDRATION, NEED_PATCH */), [
                [_vModelSelect, _ctx.assign_permission.role_id]
              ])
            ])
          ]),
          _createCommentVNode("end::Search")
        ]),
        _createCommentVNode("begin::Card title"),
        _createCommentVNode("begin::Card toolbar"),
        _createCommentVNode("add new permission button"),
        (_ctx.assigned_permissions.includes('create-permission'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(_component_Newpermission)
            ]))
          : _createCommentVNode("v-if", true),
        _createCommentVNode("end::Card toolbar")
      ]),
      _createCommentVNode("permission managment title end  "),
      _createCommentVNode("permission body "),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features_list, (feature, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-lg-3 col-md-4 mt-5",
              key: index
            }, [
              _createElementVNode("div", _hoisted_15, _toDisplayString(feature.name), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("ul", _hoisted_17, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.permissions, (permission, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      (feature.id === permission.feature_id)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_18, [
                            _withDirectives(_createElementVNode("input", {
                              class: "form-check-input border border-primary",
                              type: "checkbox",
                              value: permission.id,
                              id: "flexRadioLg",
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.assign_permission.permission_id) = $event)),
                              checked: 
                      _ctx.assign_permission.permission_id.includes(permission.id)
                        ? 1
                        : 0
                    
                            }, null, 8 /* PROPS */, _hoisted_19), [
                              [_vModelCheckbox, _ctx.assign_permission.permission_id]
                            ]),
                            _createElementVNode("span", _hoisted_20, _toDisplayString(permission.name), 1 /* TEXT */),
                            _createCommentVNode(" <i\r\n                    @click=\"unAssignPermission(permission.id)\"\r\n                    class=\"fas fa-times text-danger\"\r\n                    aria-hidden=\"true\"\r\n                  ></i>\r\n                  <b-spinner\r\n                    v-if=\"loading && permissionId == permission.id\"\r\n                    class=\"mt-2\"\r\n                    small\r\n                    label=\"Small Spinner\"\r\n                  ></b-spinner> ")
                          ]))
                        : _createCommentVNode("v-if", true)
                    ], 64 /* STABLE_FRAGMENT */))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        (_ctx.assigned_permissions.includes('assign-permission'))
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-primary mt-4 mb-10",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.assignpermission(_ctx.assign_permission)))
            }, [
              _cache[9] || (_cache[9] = _createTextVNode(" Save ")),
              (_ctx.assigpermission_loading)
                ? (_openBlock(), _createBlock(_component_b_spinner, {
                    key: 0,
                    class: "mt-2",
                    small: "",
                    label: "Small Spinner"
                  }))
                : _createCommentVNode("v-if", true)
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createCommentVNode("permission body end  "),
      _createCommentVNode("end::Card body")
    ]),
    _createCommentVNode("end::Card")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}