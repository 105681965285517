import { createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  tabindex: "-1",
  id: "addusermodal"
}
const _hoisted_2 = { class: "modal-dialog" }
const _hoisted_3 = { class: "modal-content roundeda" }
const _hoisted_4 = { class: "modal-header bg-primary" }
const _hoisted_5 = {
  key: 0,
  class: "modal-title h5 text-white text-uppercase fs-5"
}
const _hoisted_6 = {
  key: 1,
  class: "modal-title text-white"
}
const _hoisted_7 = { class: "modal-body" }
const _hoisted_8 = {
  key: 0,
  class: "form-group row m-5"
}
const _hoisted_9 = { class: "col-sm-9" }
const _hoisted_10 = {
  key: 1,
  class: "form-group row m-5"
}
const _hoisted_11 = { class: "col-sm-9" }
const _hoisted_12 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_spinner = _resolveComponent("b-spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "btn btn-primary",
      "data-bs-toggle": "modal",
      "data-bs-target": "#addusermodal",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.bol_value = false))
    }, " Add New Roles "),
    _createCommentVNode("model for New Role "),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.bol_value === false)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Add New Role "))
              : _createCommentVNode("v-if", true),
            (_ctx.bol_value === true)
              ? (_openBlock(), _createElementBlock("h4", _hoisted_6, " Edit Role "))
              : _createCommentVNode("v-if", true),
            _createCommentVNode("begin::Close"),
            _cache[5] || (_cache[5] = _createElementVNode("div", {
              class: "btn btn-icon btn-sm",
              "data-bs-dismiss": "modal",
              "aria-label": "Close",
              id: "closebutton"
            }, [
              _createElementVNode("i", {
                class: "bi bi-x-circle-fill light-text fs-3",
                style: {"color":"white"}
              })
            ], -1 /* HOISTED */)),
            _createCommentVNode("end::Close")
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("form", null, [
              (_ctx.bol_value === false)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _cache[6] || (_cache[6] = _createElementVNode("label", {
                      for: "staticEmail",
                      class: "col-sm-3 col-form-label text-primary"
                    }, "Role Name", -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_9, [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "form-control",
                        id: "inputPassword",
                        placeholder: "",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.new_role.name) = $event))
                      }, null, 512 /* NEED_PATCH */), [
                        [_vModelText, _ctx.new_role.name]
                      ])
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              (_ctx.bol_value === true)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _cache[7] || (_cache[7] = _createElementVNode("label", {
                      for: "staticEmail",
                      class: "col-sm-3 col-form-label text-primary"
                    }, "Role Name", -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_11, [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "form-control",
                        id: "inputPassword",
                        placeholder: "",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payloadValue.name) = $event))
                      }, null, 512 /* NEED_PATCH */), [
                        [_vModelText, _ctx.payloadValue.name]
                      ])
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[10] || (_cache[10] = _createElementVNode("button", {
              type: "button",
              class: "btn btn-light",
              "data-bs-dismiss": "modal",
              id: "closebutton"
            }, " Close ", -1 /* HOISTED */)),
            (_ctx.bol_value === false)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addnew_Role(_ctx.new_role)))
                }, [
                  _cache[8] || (_cache[8] = _createTextVNode(" Save ")),
                  (_ctx.loading)
                    ? (_openBlock(), _createBlock(_component_b_spinner, {
                        key: 0,
                        class: "mt-2",
                        small: "",
                        label: "Small Spinner"
                      }))
                    : _createCommentVNode("v-if", true)
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.bol_value === true)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.update_roles(_ctx.payloadValue)))
                }, [
                  _cache[9] || (_cache[9] = _createTextVNode(" Edit ")),
                  (_ctx.loading)
                    ? (_openBlock(), _createBlock(_component_b_spinner, {
                        key: 0,
                        class: "mt-2",
                        small: "",
                        label: "Small Spinner"
                      }))
                    : _createCommentVNode("v-if", true)
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ])
    ]),
    _createCommentVNode("model for New Role end  ")
  ], 64 /* STABLE_FRAGMENT */))
}