import { createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "tab-pane fade show active",
  id: "pills-home",
  role: "tabpanel",
  "aria-labelledby": "pills-home-tab"
}
const _hoisted_2 = {
  class: "modal fade",
  tabindex: "-1",
  id: "kt_modal_1"
}
const _hoisted_3 = { class: "modal-dialog modal-lg" }
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = { class: "modal-body p-10" }
const _hoisted_6 = { class: "form-group row mb-5" }
const _hoisted_7 = { class: "col-sm-8" }
const _hoisted_8 = { class: "form-group row mb-5" }
const _hoisted_9 = { class: "col-sm-8" }
const _hoisted_10 = { class: "form-group row" }
const _hoisted_11 = { class: "col-sm-8" }
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "modal-footer" }
const _hoisted_14 = {
  class: "modal fade",
  tabindex: "-1",
  id: "addnewresource",
  ref: "createAPIKeyModalRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Managepermission = _resolveComponent("Managepermission")!
  const _component_ManageFeatures = _resolveComponent("ManageFeatures")!
  const _component_EditPermission = _resolveComponent("EditPermission")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[14] || (_cache[14] = _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary me-3",
        "data-bs-toggle": "modal",
        "data-bs-target": "#permission_update_modal"
      }, " Permission ", -1 /* HOISTED */)),
      _cache[15] || (_cache[15] = _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary me-4",
        "data-bs-toggle": "modal",
        "data-bs-target": "#feature_update_modal"
      }, " Features ", -1 /* HOISTED */)),
      _cache[16] || (_cache[16] = _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary me-4",
        "data-bs-toggle": "modal",
        "data-bs-target": "#update_manage_permission"
      }, " Assign Permission ", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "modal-header bg-primary" }, [
              _createElementVNode("h4", { class: "modal-title text-white ms-3" }, "Add New Permission"),
              _createCommentVNode("begin::Close"),
              _createElementVNode("div", {
                class: "btn btn-icon btn-sm btn-active-light-primary ms-2",
                "data-bs-dismiss": "modal",
                "aria-label": "Close"
              }, [
                _createElementVNode("span", { class: "svg-icon svg-icon-2x" })
              ]),
              _createCommentVNode("end::Close")
            ], -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("form", null, [
                _createElementVNode("div", _hoisted_6, [
                  _cache[5] || (_cache[5] = _createElementVNode("label", {
                    for: "staticEmail",
                    class: "col-sm-3 col-form-label text-primary"
                  }, "Name", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_7, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      id: "inputPassword",
                      placeholder: "",
                      required: "",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.name) = $event))
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, _ctx.payload.name]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _cache[6] || (_cache[6] = _createElementVNode("label", {
                    for: "staticEmail",
                    class: "col-sm-3 col-form-label text-primary"
                  }, "Action", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_9, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      id: "inputPassword",
                      placeholder: "",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.action) = $event))
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, _ctx.payload.action]
                    ])
                  ]),
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-sm-1" }, [
                    _createElementVNode("i", {
                      class: "bi bi-info-circle fa-3x text-primary",
                      title: "This action is use as permission identifier in programming.i.e ‘u-a’ refer as ‘User Add’ permission."
                    })
                  ], -1 /* HOISTED */))
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[9] || (_cache[9] = _createElementVNode("label", {
                    for: "staticEmail",
                    class: "col-sm-3 col-form-label text-primary"
                  }, "Features", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_11, [
                    _withDirectives(_createElementVNode("select", {
                      class: "form-select border border-primary form-select-solid",
                      "data-control": "select2",
                      onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onCustomerChange && _ctx.onCustomerChange(...args))),
                      "data-placeholder": "Select an option",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.feature_id) = $event))
                    }, [
                      _cache[8] || (_cache[8] = _createElementVNode("option", { value: "" }, "Select Resource", -1 /* HOISTED */)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.feature_list, (feature, index) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: index,
                          value: feature.id
                        }, _toDisplayString(feature.name), 9 /* TEXT, PROPS */, _hoisted_12))
                      }), 128 /* KEYED_FRAGMENT */))
                    ], 544 /* NEED_HYDRATION, NEED_PATCH */), [
                      [_vModelSelect, _ctx.payload.feature_id]
                    ])
                  ]),
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "col-sm-1" }, [
                    _createElementVNode("div", {
                      type: "button",
                      class: "btn btn-primary fs-5",
                      "data-bs-toggle": "modal",
                      "data-bs-target": "#addnewresource",
                      "data-bs-dismiss": "modal"
                    }, " + ")
                  ], -1 /* HOISTED */))
                ])
              ]),
              _createCommentVNode("begin::Close"),
              _cache[11] || (_cache[11] = _createElementVNode("div", {
                class: "btn btn-icon btn-sm btn-active-light-primary ms-2",
                "data-bs-dismiss": "modal",
                "aria-label": "Close"
              }, [
                _createElementVNode("span", { class: "svg-icon svg-icon-2x" })
              ], -1 /* HOISTED */))
            ]),
            _createElementVNode("div", _hoisted_13, [
              _cache[12] || (_cache[12] = _createElementVNode("button", {
                type: "button",
                class: "btn btn-light",
                "data-bs-dismiss": "modal"
              }, " Close ", -1 /* HOISTED */)),
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-primary",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.add_New_Permission(_ctx.payload)))
              }, " Save ")
            ])
          ])
        ])
      ]),
      _createCommentVNode("one tab end ")
    ]),
    _createElementVNode("div", _hoisted_14, _cache[17] || (_cache[17] = [
      _createStaticVNode("<div class=\"modal-dialog\"><div class=\"modal-content\"><div class=\"modal-header bg-primary\"><h5 class=\"modal-title text-white ms-2\">Add New Features</h5><!--begin::Close--><div class=\"btn btn-icon btn-sm btn-active-light-primary ms-2\" data-bs-dismiss=\"modal\" aria-label=\"Close\" id=\"addnewclosemodal\"><span class=\"svg-icon svg-icon-2x\"></span></div><!--end::Close--></div><div class=\"modal-body p-5\"></div><div class=\"modal-footer\"></div></div></div>", 1)
    ]), 512 /* NEED_PATCH */),
    _createVNode(_component_Managepermission),
    _createVNode(_component_ManageFeatures),
    _createVNode(_component_EditPermission)
  ], 64 /* STABLE_FRAGMENT */))
}