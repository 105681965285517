import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  ref: "createAPIKeyModalRef",
  id: "update_manage_permission",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-body scroll-y mx-5 mx-xl-15 my-7" }
const _hoisted_5 = { class: "row fv-row" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = { class: "d-flex flex-column fv-row" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "fv-plugins-message-container" }
const _hoisted_10 = { class: "fv-help-block" }
const _hoisted_11 = { class: "col-6" }
const _hoisted_12 = { class: "d-flex flex-column fv-row" }
const _hoisted_13 = ["value"]
const _hoisted_14 = { class: "fv-plugins-message-container" }
const _hoisted_15 = { class: "fv-help-block" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-md-8" }
const _hoisted_18 = {
  key: 0,
  class: "list-group-item"
}
const _hoisted_19 = { class: "text-end pt-5" }
const _hoisted_20 = {
  ref: "submitButtonRef",
  type: "submit",
  id: "kt_modal_bin_submit",
  class: "btn btn-primary float-left"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Modal - New Card"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Modal dialog"),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Modal content"),
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode("begin::Modal header"),
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "modal-header bg-primary" }, [
            _createCommentVNode("begin::Modal title"),
            _createElementVNode("span", { class: "text-white ms-4 h5 text-uppercase" }, "Update Manage Permission"),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-icon btn-sm",
              "data-bs-dismiss": "modal",
              "aria-label": "Close",
              id: "addnewnotificationmodal"
            }, [
              _createElementVNode("i", {
                class: "bi bi-x-circle-fill light-text fs-3",
                style: {"color":"white"}
              })
            ]),
            _createCommentVNode("end::Modal title")
          ], -1 /* HOISTED */)),
          _createCommentVNode("end::Modal header"),
          _createCommentVNode("begin::Modal body"),
          _createElementVNode("div", _hoisted_4, [
            _createCommentVNode("begin::Form"),
            _createVNode(_component_Form, {
              id: "kt_modal_bin_form",
              class: "form",
              onSubmit: _ctx.submit,
              "validation-schema": _ctx.validationSchema
            }, {
              default: _withCtx(() => [
                _createCommentVNode("begin::Input group"),
                _createCommentVNode(" <div class=\"row mb-12\"> "),
                _createCommentVNode("begin::Col"),
                _createCommentVNode(" <div class=\"col-md-6 fv-row\"> "),
                _createCommentVNode("begin::Row"),
                _createElementVNode("div", _hoisted_5, [
                  _createCommentVNode("begin::Col"),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createCommentVNode("begin::Label"),
                      _cache[3] || (_cache[3] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Features", -1 /* HOISTED */)),
                      _createCommentVNode("end::Label"),
                      _createCommentVNode("begin::Select"),
                      _createVNode(_component_Field, {
                        name: "feature_id",
                        "data-control": "select2",
                        "data-hide-search": "true",
                        "data-placeholder": "Select Type",
                        class: "form-select border border-primary form-select-solid",
                        as: "select",
                        modelValue: _ctx.manage_permission.feature_id,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.manage_permission.feature_id) = $event))
                      }, {
                        default: _withCtx(() => [
                          _cache[2] || (_cache[2] = _createElementVNode("option", { value: "" }, "Select Feature", -1 /* HOISTED */)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.feature_list, (feature, i) => {
                            return (_openBlock(), _createElementBlock("option", {
                              key: i,
                              value: feature.id
                            }, _toDisplayString(feature.name), 9 /* TEXT, PROPS */, _hoisted_8))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue"]),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_ErrorMessage, { name: "feature_id" })
                        ])
                      ]),
                      _createCommentVNode("end::Select")
                    ])
                  ]),
                  _createCommentVNode("end::Col"),
                  _createCommentVNode("begin::Col"),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createCommentVNode("begin::Label"),
                      _cache[5] || (_cache[5] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Permissions ", -1 /* HOISTED */)),
                      _createCommentVNode("end::Label"),
                      _createCommentVNode("begin::Select"),
                      _createVNode(_component_Field, {
                        name: "permission_id",
                        "data-control": "select2",
                        "data-hide-search": "true",
                        "data-placeholder": "Select Type",
                        class: "form-select border border-primary form-select-solid",
                        as: "select",
                        modelValue: _ctx.manage_permission.permission_id,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.manage_permission.permission_id) = $event))
                      }, {
                        default: _withCtx(() => [
                          _cache[4] || (_cache[4] = _createElementVNode("option", { value: "" }, "Select Permission", -1 /* HOISTED */)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.permission_list, (permission, i) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                              (permission.feature_id === null)
                                ? (_openBlock(), _createElementBlock("option", {
                                    key: 0,
                                    value: permission.id
                                  }, _toDisplayString(permission.name), 9 /* TEXT, PROPS */, _hoisted_13))
                                : _createCommentVNode("v-if", true)
                            ], 64 /* STABLE_FRAGMENT */))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue"]),
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _createVNode(_component_ErrorMessage, { name: "permission_id" })
                        ])
                      ]),
                      _createCommentVNode("end::Select")
                    ])
                  ]),
                  _createCommentVNode("end::Col")
                ]),
                _createCommentVNode("end::Row"),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.permission_list, (permission, index) => {
                      return (_openBlock(), _createElementBlock("ul", {
                        class: "list-group list-group-flush",
                        key: index
                      }, [
                        (_ctx.manage_permission.feature_id == permission.feature_id)
                          ? (_openBlock(), _createElementBlock("li", _hoisted_18, _toDisplayString(permission.name), 1 /* TEXT */))
                          : _createCommentVNode("v-if", true)
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]),
                _createCommentVNode(" </div> "),
                _createCommentVNode("end::Col"),
                _createCommentVNode(" </div> "),
                _createCommentVNode("end::Input group"),
                _createCommentVNode("begin::Actions"),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("button", _hoisted_20, _cache[6] || (_cache[6] = [
                    _createElementVNode("span", { class: "indicator-label" }, " Submit ", -1 /* HOISTED */),
                    _createElementVNode("span", { class: "indicator-progress" }, [
                      _createTextVNode(" Please wait... "),
                      _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                    ], -1 /* HOISTED */)
                  ]), 512 /* NEED_PATCH */)
                ]),
                _createCommentVNode("end::Actions")
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onSubmit", "validation-schema"]),
            _createCommentVNode("end::Form")
          ]),
          _createCommentVNode("end::Modal body")
        ]),
        _createCommentVNode("end::Modal content")
      ]),
      _createCommentVNode("end::Modal dialog")
    ], 512 /* NEED_PATCH */),
    _createCommentVNode("end::Modal - New Card")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}